<template>
  <!-- 虚拟发货 -->
  <div class="wrapper">
    <el-form ref="form" :model="formData" label-width="120px">
      <el-form-item label="采购成本" prop="costPrice" :rules="rules">
        <el-input-number
          style="width:400px"
          v-model="formData.costPrice"
          size="small"
          :precision="2"
          :step="0.01"
          :min="0"
          step-strictly
        ></el-input-number>
      </el-form-item>
      <el-form-item label="备注">
        <el-input style="width:400px" v-model="formData.deliveryRemark" type="textarea" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="" size="small" @click="closePage">取消</el-button>
        <el-button type="primary" size="small" @click="save">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { handleDelieryVirtualAPI } from './api'
export default {
  name: 'DeliveryVirtual',

  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        orderNo: this.$route.params.id,
        costPrice: '',
        deliveryRemark: ''
      }
      
    }
  },

  mounted() {
   
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          handleDelieryVirtualAPI(this.formData).then(() => {
            this.$message.success('发货成功')
            //
            setTimeout(() => {
              this.closePage()
            }, 1000)
          })
        }
      })
    },
    emitAwindow() {
      //window.opener 获取父页面的window元素
      //判断A窗口有没有window.opener和getBpageList是不是个方法
      if (window.opener && window.opener.getBpageList) {
        window.opener.getBpageList()
      } else {
        //window.opener.frames[0] 获取到的window对象
        window.opener.frames[0].getBpageList()
      }
    },
    closePage() {
      this.emitAwindow()
      window.close()
    },
  
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
</style>
