<template>
  <el-select
    :style="{ width: width }"
    size="small"
    v-model="roleId"
    :loading="isLoading"
    filterable
    remote
    :remote-method="getList"
    placeholder="请选择"
    clearable
  >
    <el-option v-for="item in list" :label="item.packageName" :value="item.packageCode" :key="item.packageCode"></el-option>
  </el-select>
</template>

<script>
import { getBenefitListAPI } from './api.js'
export default {
  name: 'SelectRole',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: String,
      default: '500px'
    }
  },

  computed: {
    roleId: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getList('')
  },

  methods: {
    async getList(query) {
      let params = {
        page: 1,
        pageSize: 20,
        storeName: query
      }
      let res = await getBenefitListAPI(params)
      this.list = res
    }
  }
}
</script>

<style></style>
